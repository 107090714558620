<template>
    <v-data-table
        :headers="headersData"
        :items="fetchingData ? [] : columnsData"
        :loading="fetchingData"
        item-key="short_id"
        :sort-desc="[true, true]"
        hide-default-footer
        class="text-truncate">
        <template v-slot:top>
            <div class="d-flex align-center">
                <v-icon left>mdi-table-large</v-icon>
                <span class="secondary--text font-weight-bold">{{ tableName }}</span>
            </div>
        </template>
        <template v-slot:no-data>
            <v-alert text prominent color="info" icon="info" class="mt-3">
                <span v-if="fetchingData">Loading column data, please wait...</span>
                <span v-else>No data available.</span>
            </v-alert>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: 'snapshot-table-preview',
    props: {
        columnsData: {
            type: Array,
            default() {
                return []
            }
        },
        headersData: Array,
        fetchingData: Boolean,
        tableName: String
    }
}
</script>
